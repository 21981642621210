import React from 'react'
import ProjectArchive from './project-archive'

import '../styles/project-archive-list.css'

function ProjectArchiveList (props) {
  return (
    <div className="project-archive-list">
    <div className="wrapper">
      {props.title && <h3>{props.title}</h3>}
    </div>
        
      <div className="grid project-archive-holder">
        {props.nodes &&
          props.nodes.map(node => (
              <ProjectArchive {...node} />
          ))}
      </div>
</div>
  )
}

ProjectArchiveList.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default ProjectArchiveList