import {Link} from 'gatsby'
import React from 'react'
import {buildImageObj, getProjectUrl} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import '../styles/project-archive.css'

function ProjectArchive (props) {

  //console.log(props);

  return (

    <div className="project-archive-item width-12-12-m width-12-12">

        {props.image.desktopImage && props.image.desktopImage.asset && props.image.mobileImage && props.image.mobileImage.asset && (
          <Link title={props.title} to={getProjectUrl(props.publishedAt, props.slug.current)}>
          <div class="info">
            <div class="inner">
              <div><h2>{props.title}</h2></div>
              <div><h3>{props.projectType}</h3></div>
            </div>
          </div>

          <div className="image-holder">
            <picture>
              <source 
                media="(max-width: 699px)" 
                srcset={imageUrlFor(buildImageObj(props.image.mobileImage))
                  .width(1000)
                  .height(Math.floor((1 / 1) * 1000))
                  .auto('format')
                  .url()}
              />
              <source 
                media="(min-width: 700px)" 
                srcset={imageUrlFor(buildImageObj(props.image.desktopImage))
                  .width(2000)
                  .height(Math.floor((1 / 2) * 2000))
                  .auto('format')
                  .url()}
              />
              <img
                src={imageUrlFor(buildImageObj(props.image.desktopImage))
                  .width(2000)
                  .height(Math.floor((1 / 2) * 2000))
                  .auto('format')
                  .url()}
                alt={props.image.desktopImage.alt}
                title={props.image.desktopImage.alt}
              />
            </picture>
          </div>
          </Link>
        )}

    </div>
  )
}

export default ProjectArchive